import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function App() {
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [categorizedNumbers, setCategorizedNumbers] = useState({
    indian91: [],
    indianPlus91: [],
    foreign: [],
  });
  const [indianCount, setIndianCount] = useState(0);
  const [foreignCount, setForeignCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState({
    existing: '',
    new: '',
  });

  const [output, setOutput] = useState('');

  const [replacedNumbers, setReplacedNumbers] = useState([]);

  const handleCategorize = async () => {
    setLoading(true);

    try {
      const categorized = await categorizeNumbers(phoneNumbers);
      setCategorizedNumbers(categorized);
      setIndianCount(categorized.indian91.length);
      setForeignCount(categorized.foreign.length);
      showSuccessToast('Contacts Conditioned!');
    } catch (error) {
      console.error(error);
      showErrorToast('Error occurred during Conditioned.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckDuplicates = async () => {
    setLoading(true);
  
    try {
      const existingList = contacts.existing.split('\n');
      const newList = contacts.new.split('\n');
      const replacedNumbersList = [];
      const replacedNumbersDict = {}; // Store replaced numbers per line
  
      const updatedNewList = [];
  
      const uniqueLines = new Set(); // Track unique lines
  
      for (const currentContact of newList) {
        if (uniqueLines.has(currentContact)) {
          // If the entire line is repeated, replace it with ' '
          replacedNumbersList.push(currentContact);
          updatedNewList.push('');
        } else {
          uniqueLines.add(currentContact);
  
          const numbersInLine = currentContact.split(':::').map(number => number.trim());
          const uniqueNumbers = new Set(); // Track unique numbers within the line
          const replacedNumbersInLine = []; // Store replaced numbers within the line
  
          const updatedNumbers = numbersInLine.map(number => {
            if (uniqueNumbers.has(number) || existingList.includes(number)) {
              replacedNumbersInLine.push(number);
              replacedNumbersList.push(number); // Add to overall replaced numbers list
              return '';
            } else {
              uniqueNumbers.add(number);
              return number;
            }
          });
  
          replacedNumbersDict[currentContact] = replacedNumbersInLine; // Store for later output
          updatedNewList.push(updatedNumbers.join(' ::: ')); // Add spaces around :::
        }
      }
  
      const updatedContacts = { ...contacts, new: updatedNewList.join('\n') };
  
      // Set replaced numbers state
      setReplacedNumbers(replacedNumbersList);
      // Set output state
      setOutput(updatedContacts.new);
  
      // Print replaced numbers in each line
      console.log("Replaced numbers in each line:");
      for (const line in replacedNumbersDict) {
        console.log(`${line}: ${replacedNumbersDict[line]}`);
      }
  
      showSuccessToast('Duplicates Checked!');
    } catch (error) {
      console.error(error);
      showErrorToast('Error occurred during check.');
    } finally {
      setLoading(false);
    }
  };
  
   
  

  
  
  

  const categorizeNumbers = (numbers) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const categorized = {
          indian91: [],
          indianPlus91: [],
          foreign: [],
        };

        numbers
          .split('\n')
          .map((number) => number.replace(/\s/g, ''))
          .forEach((number) => {
            const formattedNumber = formatNumber(number);
            if (formattedNumber) {
              categorized[formattedNumber.category].push(formattedNumber.value);
              if (formattedNumber.category === 'indian91') {
                categorized.indianPlus91.push('+' + formattedNumber.value);
              } else if (formattedNumber.category === 'indianPlus91') {
                categorized.indian91.push(formattedNumber.value.substring(1));
              }
            }
          });

        resolve(categorized);
      }, 1500);
    });
  };

  const formatNumber = (number) => {
    const length = number.length;
    if (length === 10) {
      return { category: 'indian91', value: '91' + number };
    } else if (length === 11 && number.startsWith('0')) {
      return { category: 'indian91', value: '91' + number.substring(1) };
    } else if (length === 13 && number.startsWith('+91')) {
      return { category: 'indianPlus91', value: number };
    } else if (length === 13 && number.startsWith('091')) {
      return { category: 'indianPlus91', value: '+' + number.substring(1) };
    } else if (length === 12 && number.startsWith('91')) {
      return { category: 'indianPlus91', value: '+' + number };
    } else {
      return { category: 'foreign', value: number };
    }
  };




  // Realtime edit to existingcontacts field

  const handleExistingContactsChange = (e) => {
    const rawExistingContacts = e.target.value;
    const formattedExistingContacts = formatExistingContacts(rawExistingContacts);
    setContacts({ ...contacts, existing: formattedExistingContacts });
  };
  
  const formatExistingContacts = (rawContacts) => {
    const formattedContacts = rawContacts
      .split('\n')
      .map((line) => {
        const numbers = line.split(':::').map((number) => number.trim());
        const formattedNumbers = numbers.map((num) => {
          return formatNumberForExistingContact(num);
        });
        return formattedNumbers.join(' ::: ');
      })
      .join('\n');
  
    return formattedContacts;
  };
  
  const formatNumberForExistingContact = (number) => {
    const formattedNumber = number.replace(/\s/g, '');
    const length = formattedNumber.length;
  
    if (length === 10) {
      return '+91' + formattedNumber;
    } else if (length === 11 && formattedNumber.startsWith('0')) {
      return '+91' + formattedNumber.substring(1);
    } else if (length === 12 && formattedNumber.startsWith('91')) {
      return '+' + formattedNumber;
    } else if (length === 13 && formattedNumber.startsWith('+91')) {
      return formattedNumber;
    } else if (length === 13 && formattedNumber.startsWith('091')) {
      return '+' + formattedNumber.substring(1);
    } else {
      return formattedNumber;
    }
  };


  // Realtime edit to newcontacts field

  const handleNewContactsChange = (e) => {
    const rawNewContacts = e.target.value;
    const formattedNewContacts = formatNewContacts(rawNewContacts);
    setContacts({ ...contacts, new: formattedNewContacts });
  };

  const formatNewContacts = (rawContacts) => {
    const formattedContacts = rawContacts
      .split('\n')
      .map((line) => {
        const numbers = line.split(':::').map((number) => number.trim());
        const formattedNumbers = numbers.map((num) => {
          return formatNumberForNewContact(num);
        });
        return formattedNumbers.join(' ::: ');
      })
      .join('\n');

    return formattedContacts;
  };

  const formatNumberForNewContact = (number) => {
    const formattedNumber = number.replace(/\s/g, '');
    const length = formattedNumber.length;
  
    if (length === 10) {
      return '+91' + formattedNumber;
    } else if (length === 11 && formattedNumber.startsWith('0')) {
      return '+91' + formattedNumber.substring(1);
    } else if (length === 12 && formattedNumber.startsWith('91')) {
      return '+' + formattedNumber;
    } else if (length === 13 && formattedNumber.startsWith('+91')) {
      return formattedNumber;
    } else if (length === 13 && formattedNumber.startsWith('091')) {
      return '+' + formattedNumber.substring(1);
    } else {
      return formattedNumber;
    }
  };

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast('Copied to clipboard!');
  };

  const showSuccessToast = (message) => {
    MySwal.fire({
      position: 'top-end',
      icon: 'success',
      title: message,
      toast: true,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
  };

  const showErrorToast = (message) => {
    MySwal.fire({
      position: 'top-end',
      icon: 'error',
      title: message,
      toast: true,
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
  };

  const renderCategory = (title, data, categoryKey) => (
    <Paper elevation={3} sx={{ p: 2, textAlign: 'center', minHeight: 200, mb: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <TextField
        disabled
        value={data.join('\n')}
        fullWidth
        multiline
        rows={6}
        variant="outlined"
        sx={{
          overflow: 'auto',
          '& textarea': {
            overflow: 'auto',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={() => handleCopyClick(data.join('\n'))}>
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', mt: 5, height: '100%' }}>
        {/* Contact Tool Section */}
        <Box sx={{ flex: '1 1 50%', paddingRight: 2 }}>
          <Typography variant="h4" gutterBottom>
            Condition Tool
          </Typography>
          <TextField
            label="Mobile numbers"
            value={phoneNumbers}
            onChange={(e) => setPhoneNumbers(e.target.value)}
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleCategorize}
            sx={{ mb: 2, width: '100%', position: 'relative' }}
            disabled={loading}
          >
            {loading && <CircularProgress size={24} sx={{ position: 'absolute', left: '50%', top: '50%', marginTop: -12, marginLeft: -12 }} />}
            {loading ? 'Conditioning...' : 'Condition'}
          </Button>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {renderCategory('Indian (91)', categorizedNumbers.indian91, 'indian91')}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderCategory('Indian (+91)', categorizedNumbers.indianPlus91, 'indianPlus91')}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderCategory('Foreign (+)', categorizedNumbers.foreign, 'foreign')}
            </Grid>
          </Grid>
          <Typography variant="body2" color="textSecondary" mt={2} textAlign="center">
            Indian Numbers: {indianCount} | Foreign Numbers: {foreignCount}
          </Typography>
        </Box>

        {/* Duplicate Checker Section */}
        <Box sx={{ flex: '1 1 50%', paddingLeft: 2 }}>
          <Typography variant="h4" gutterBottom>
            Duplicate Checker
          </Typography>
          <Grid container spacing={3}>
            {/* Left input for existing contacts */}
            <Grid item xs={6}>
              <TextField
                label="Existing contacts"
                value={contacts.existing}
                onChange={(e) => {
                  const rawExistingContacts = e.target.value;
                  const formattedExistingContacts = formatExistingContacts(rawExistingContacts);
                  setContacts({ ...contacts, existing: formattedExistingContacts });
                }}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            {/* Right input for new contacts */}
            <Grid item xs={6}>
              <TextField
                label="New contacts"
                value={contacts.new}
                onChange={(e) => {
                  const rawNewContacts = e.target.value;
                  const formattedNewContacts = formatNewContacts(rawNewContacts);
                  setContacts({ ...contacts, new: formattedNewContacts });
                }}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleCheckDuplicates}
            sx={{ mb: 2, width: '100%', position: 'relative' }}
            disabled={loading}
          >
            {loading && <CircularProgress size={24} sx={{ position: 'absolute', left: '50%', top: '50%', marginTop: -12, marginLeft: -12 }} />}
            {loading ? 'Checking...' : 'Check Duplicates'}
          </Button>
          {/* Display replaced numbers */}
          <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
          <Paper elevation={3} sx={{ p: 3, textAlign: 'center', minHeight: 200, mb: 2 }}>
  <Typography variant="h6">Replaced Numbers</Typography>
  <TextField
    disabled
    value={replacedNumbers.join('\n')}
    multiline
    fullWidth
    rows={6}
    variant="outlined"
    sx={{
      overflow: 'auto',
      '& textarea': {
        overflow: 'auto',
      },
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={() => handleCopyClick(replacedNumbers.join('\n'))}>
              <FileCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />
</Paper>

<Paper elevation={3} sx={{ p: 3, textAlign: 'center', minHeight: 200 }}>
            <Typography variant="h6">Output</Typography>
            {/* Display output in the "Output" Paper */}
            <TextField
              disabled
              value={output}
              multiline
              fullWidth
              rows={6}
              variant="outlined"
              sx={{
                overflow: 'auto',
                '& textarea': {
                  overflow: 'auto',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy to clipboard">
                      <IconButton onClick={() => handleCopyClick(output)}>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
          </div>
        </Box>
      </Box>
      <Typography variant="body2" color="textSecondary" mt={2} textAlign="center">
        Developed with ❤️ by Sanjith
      </Typography>
    </Container>
  );
}

export default App;
